import * as React from "react";

import { z } from "zod";
import { each } from "lodash";
import { cn } from "@/lib/utils"
import { router } from "@inertiajs/react";
import { useForm } from "react-hook-form";
import { Icons } from "@/Components/icons";
import { Button } from "@/Components/ui/button";
import { Input } from "@/Components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormMessage,
} from "@/Components/ui/form";

const FormSchema = z.object({
    email: z.string()
        .email({ message: "Please enter a valid email address" }),
});

type formValues = z.infer<typeof FormSchema>;

interface AuthEmailFormProps extends React.HTMLAttributes<HTMLDivElement> { }

function handleFormErrors(errors: Record<string, string>, form: ReturnType<typeof useForm<formValues>>) {
    each(errors, (message, field) => {
        form.setError(field as keyof formValues, {
            type: "custom",
            message: message,
        });
    });
}

export function AuthEmailForm({ className, ...props }: AuthEmailFormProps) {
    const [isLoading, setIsLoading] = React.useState<boolean>(false)

    const form = useForm<formValues>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            email: ''
        },
    });

    const onGoogleLogin = () => {
        form.setError("email", {
            type: "manual",
            message: "Google login isn't available yet. Please continue with your email."
        });
    }


    const onSubmit = (data: formValues) => {
        const options = {
            onStart: () => setIsLoading(true),
            onFinish: () => setIsLoading(false),
            onError: (errors: Record<string, string>) => handleFormErrors(errors, form),
        };

        router.post(route("sign-in.store"), data, options);
    }

    return (
        <div className={cn("grid gap-6", className)} {...props}>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div className="grid gap-2">
                        <div className="grid gap-1">
                            <FormField
                                control={form.control}
                                name="email"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input
                                                type="email"
                                                placeholder="name@example.com"
                                                autoCapitalize="none"
                                                autoComplete="email"
                                                autoCorrect="off"
                                                disabled={isLoading}
                                                autoFocus
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>

                        <Button disabled={isLoading}>
                            {isLoading && (
                                <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
                            )}
                            Sign In with Email
                        </Button>
                    </div>
                </form>
            </Form>
            <div className="relative">
                <div className="absolute inset-0 flex items-center">
                    <span className="w-full border-t" />
                </div>
                <div className="relative flex justify-center text-xs uppercase">
                    <span className="bg-background px-2 text-muted-foreground">
                        Or continue with
                    </span>
                </div>
            </div>
            <Button variant="outline" type="button" onClick={onGoogleLogin}>
                <Icons.google className="mr-2 h-4 w-4" /> Google
            </Button>
        </div>
    )
}
